import { Controller } from 'stimulus'

export default class extends Controller {

  static targets = ['link']

  click() {
    this.linkTarget.click();
  }

  stopPropagation(event) {
    event.stopPropagation();
  }

}