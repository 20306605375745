import { Controller } from 'stimulus'
import SlimSelect from 'slim-select'

export default class extends Controller {
  static targets = [
    'typeSelector',
    'idSelector'
  ]

  static values = {
    authorOptions: Array,
    organizationOptions: Array
  }

  connect() {
    this.slimSelect = new SlimSelect({
      select: this.idSelectorTarget
    })
  }

  change() {
    const type = this.typeSelectorTarget.value
    let options
    if (type === 'Organization') {
      options = this.organizationOptionsValue.map(([text, value]) => { return {text: text, value: value} })
      options.unshift({placeholder: true, text: 'Please select an author'})
    } else {
      options = this.authorOptionsValue.map(([text, value]) => { return {text: text, value: value} })
      options.unshift({placeholder: true, text: 'Please select an author'})
    }
      this.slimSelect.setData(options)
  }
}
