import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['addAnchor', 'template']

  initialize() {
    this.wrapperSelector = this.data.get('wrapperSelector') || '.nested-form-wrapper'
  }

  add(event) {
    event.preventDefault()

    const content = this.templateTarget.innerHTML.replace(/NEW_RECORD/g, new Date().getTime())
    this.addAnchorTarget.insertAdjacentHTML('beforebegin', content)
  }

  remove(event) {
    event.preventDefault()

    const wrapper = event.target.closest(this.wrapperSelector)

    if (wrapper.dataset.newRecord === 'true') {
      wrapper.remove()
    } else {
      wrapper.style.display = 'none'
      wrapper.querySelector("input[name*='_destroy']").value = 1
    }
  }

}
