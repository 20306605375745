import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['body']

  toggle(event) {
    event.preventDefault()
    event.stopPropagation()

    this.bodyTarget.classList.toggle('hidden')
    this.bodyTarget.classList.toggle('flex')
  }

  hide() {
    this.bodyTarget.classList.add('hidden')
    this.bodyTarget.classList.remove('flex')
  }

}