import { Controller } from 'stimulus'

export default class extends Controller {

  static targets = ['button', 'error']
  static values = { 'stripePublicKey': String }

  redirect() {
    const controller = this

    this.createCheckoutSession().then(function(data) {
      Stripe(controller.stripePublicKeyValue).redirectToCheckout({
        sessionId: data.sessionId
      }).then(function (result) {
        if (result.error) {
            controller.errorTarget.innerText = result.error.message;
          }
      });
    })
  }

  createCheckoutSession() {
    return fetch("/sponsor/checkout/session", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": document.querySelector("[name='csrf-token']").content
      }
    }).then(function(result) {
      return result.json();
    })
  }

}