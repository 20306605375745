import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    'instructorIDSelectorTemplate',
    'organizationIDSelectorTemplate',
    'typeSelector',
    'idSelector'
  ]

  change() {
    const type = this.typeSelectorTarget.value
    if (type === 'Organization') {
      const parentNode = this.idSelectorTarget.parentNode
      this.idSelectorTarget.remove()
      parentNode.insertAdjacentHTML('beforeend', this.organizationIDSelectorTemplateTarget.innerHTML)
    } else {
      const parentNode = this.idSelectorTarget.parentNode
      this.idSelectorTarget.remove()
      parentNode.insertAdjacentHTML('beforeend', this.instructorIDSelectorTemplateTarget.innerHTML)
    }
  }
}
